import classNames from "classnames";
import { type FC } from "react";

interface Props {
  variant?: string;
}

const PlaceholderLine: FC<{ variant?: string; height: string }> = ({
  variant,
  height,
}) => (
  <div
    className={classNames(
      `rounded bg-[#dfdfdf] ${height}`,
      variant === "shopping" && "hidden lg:block",
    )}
  ></div>
);

export const PlaceholderBody: FC<Props> = ({ variant }) => {
  const lines = [{ height: "h-7" }, { height: "h-4" }, { height: "h-4" }];

  return (
    <>
      {lines.map((line, index) => (
        <PlaceholderLine key={index} variant={variant} height={line.height} />
      ))}
    </>
  );
};
