/**
 * Returns an abbreviated count string.
 * @param count - The count to be abbreviated.
 * @returns The abbreviated count string.
 */
export const getAbbreviatedCount = (count: number): string => {
  const formatNumber = (num: number, suffix: string) => {
    if (num % 1 === 0) {
      return num.toFixed(0) + suffix; // ensures 1K and not 1.0K
    }
    return num.toFixed(1).replace(/\.0$/, "") + suffix; // removes trailing .0
  };

  if (count < 1000) {
    return count.toString();
  } else if (count < 1_000_000) {
    return formatNumber(count / 1000, "K");
  } else if (count < 1_000_000_000) {
    return formatNumber(count / 1_000_000, "M");
  } else {
    return formatNumber(count / 1_000_000_000, "B");
  }
};
