import { Icon } from "@yahoo/uds";
import {
  Lightbulb,
  PaperPlaneDiagonal,
  SpeechBubble,
  TrendingCircle,
} from "@yahoo/uds-icons";
import { type SvgIconProps, type SvgIcon } from "@yahoo/uds-icons/types";
import classNames from "classnames";
import React, { type FC } from "react";
import { useIntl } from "react-intl";
import { type Badge } from "@/components/common/badge/badge";
import { AttributionImage } from "./Attribution/AttributionImage";

type Props = {
  badges: Badge[];
  className?: string;
  showCreatorLogo?: boolean;
  badgeColor?: string;
};

const contexualBadgesClassNames =
  "text-batcave dark:text-grey-hair tracking-[.033rem] items-center";
const badgeIconClassNames = "mr-1.5 text-hulk-pants";
const blueBold = "text-[#0f69ff]";
const tealBold = "text-[#14728e]";
const purpleBold = "text-[#a42e97]";
const pinkBold = "text-[#dc0276]";
const commonBadgeClasses = "font-bold uppercase";

const badgeStyles: {
  [k: string]: {
    color?: string;
    icon?: SvgIcon;
    iconProps?: SvgIconProps & { className?: string };
    isLive?: boolean;
  };
} = {
  BUSINESS: {
    color: tealBold,
  },
  CELEBRITY: {
    color: pinkBold,
  },
  CREATOR: {
    color: contexualBadgesClassNames,
  },
  ENTERTAINMENT: {
    color: pinkBold,
  },
  FINANCE: {
    color: "text-[#006c3b]",
  },
  HEALTH: {
    color: purpleBold,
  },
  LIFESTYLE: {
    color: purpleBold,
  },
  LIVE: {
    color: "text-[#d30d2e]",
    isLive: true,
  },
  MOST_COMMENTED: {
    color: contexualBadgesClassNames,
    icon: SpeechBubble,
    iconProps: {
      className: badgeIconClassNames,
      size: "sm",
      variant: "fill",
    },
  },
  MOST_SHARED: {
    color: contexualBadgesClassNames,
    icon: PaperPlaneDiagonal,
    iconProps: {
      className: badgeIconClassNames,
      size: "sm",
      variant: "fill",
    },
  },
  NEWS: {
    color: blueBold,
  },
  OPINION: {
    color: contexualBadgesClassNames,
    icon: Lightbulb,
    iconProps: {
      className: badgeIconClassNames,
      size: "sm",
      variant: "fill",
    },
  },
  POLITICS: {
    color: "text-[#2b0067]",
  },
  POPULAR: {
    color: contexualBadgesClassNames,
    icon: TrendingCircle,
    iconProps: {
      className: badgeIconClassNames,
      size: "sm",
      variant: "fill",
    },
  },
  SCIENCE: {
    color: tealBold,
  },
  SPORTS: {
    color: "text-[#bf4900]",
  },
  STYLE: {
    color: purpleBold,
  },
  TECHNOLOGY: {
    color: tealBold,
  },
  US: {
    color: blueBold,
  },
  WORLD: {
    color: blueBold,
  },
};

const BadgeElement: FC<Badge> = ({
  id,
  value,
  provider,
  showCreatorLogo,
  badgeColor,
}) => {
  const intl = useIntl();

  const label = intl.formatMessage(
    {
      id: `stream.BADGE_${id}`,
    },
    { value },
  );
  const { color, icon, iconProps, isLive } = badgeStyles[id];
  const brandImage =
    provider?.providerBrand?.imageByType || provider?.providerBrand?.logoImage;

  return (
    <div
      className={classNames(
        badgeColor || color,
        "flex text-nowrap text-[.8125rem] leading-4 tracking-[0.01625rem]",
        isLive && "items-center gap-1",
        commonBadgeClasses,
      )}
    >
      {icon && (
        <Icon
          className={iconProps?.className}
          name={icon}
          size={iconProps?.size}
          variant={iconProps?.variant}
        />
      )}
      {isLive && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <circle cx="6" cy="6" r="3" fill="#EB434C" />
          <circle
            opacity="0.5"
            cx="6"
            cy="6"
            r="5"
            stroke="#EB434C"
            stroke-width="2"
          />
        </svg>
      )}
      {showCreatorLogo && brandImage && (
        <AttributionImage imageUrl={brandImage.originalUrl} />
      )}
      {label}
    </div>
  );
};

export const Badges: FC<Props> = ({
  badges,
  className,
  showCreatorLogo,
  badgeColor,
}) => {
  const firstBadge = badges[0];
  return (
    <div className={classNames(className, "flex")}>
      {firstBadge && (
        <BadgeElement
          showCreatorLogo={showCreatorLogo}
          {...firstBadge}
          badgeColor={badgeColor}
        />
      )}
    </div>
  );
};
