import classNames from "classnames";
import { useContext } from "react";
import { ArticleLink } from "@/components/common/ArticleLink";
import { type Badge, getBadgeI13n } from "@/components/common/badge/badge";
import { useNcpCacheContext } from "@/contexts/NcpCacheContext";
import { COMMON_STREAM_LINK_I13N_PARAMS } from "@/lib/i13n";
import { I13nStartIndexContext } from "@/lib/I13nStartIndexContext";
import { STORY_VARIANTS, type StoryVariant } from "../StoryContent";

export type HeadingProps = {
  badges?: Badge[];
  bypassModal: boolean;
  cacheId?: string;
  categoryLabel: string;
  cpos?: number;
  id: string;
  i13nSubsec?: string;
  isActiveStory: boolean;
  moduleTitle?: string;
  title: string;
  url: string;
  variant: StoryVariant;
};

export const StoryHeading = ({
  badges,
  bypassModal,
  cacheId,
  categoryLabel,
  cpos,
  i13nSubsec,
  id,
  isActiveStory,
  moduleTitle,
  title,
  url,
  variant,
}: HeadingProps) => {
  const i13nStartIndex = useContext(I13nStartIndexContext);
  const { articlePosition, setArticlePosition } = useNcpCacheContext();

  const resumePosition = () => {
    setArticlePosition({
      ...articlePosition,
      stream: cacheId || "",
    });
  };

  let linkClasses = "";

  switch (variant) {
    case STORY_VARIANTS.FOLLOW: {
      linkClasses = "headline-s lg:headline-l xl:headline-xl font-medium";
      break;
    }
    case STORY_VARIANTS.LEAD: {
      linkClasses = "headline-l lg:headline-l xl:headline-xl font-medium";
      break;
    }
    case STORY_VARIANTS.NEBULA: {
      linkClasses =
        "leading-5 sm:text-[1.125rem] sm:leading-[1.375rem] md:text-[1.25rem] md:leading-[1.5rem] lg:leading-[1.625rem] font-semibold lg:font-[550] text-inkwell";
      break;
    }
    case STORY_VARIANTS.SHOPPING: {
      linkClasses = "lg:headline-m md:headline-l headline-m font-semibold";
      break;
    }
    default: {
      linkClasses =
        "headline-s sm:headline-m md:headline-l xl:headline-xl font-semibold";
      break;
    }
  }

  return (
    <h3>
      <ArticleLink
        className={classNames(
          "stretched-box group-hover:underline",
          {
            "line-clamp-3 text-base sm:text-lg md:line-clamp-4 md:text-xl":
              variant === STORY_VARIANTS.NEBULA,
          },
          badges || variant === STORY_VARIANTS.SHOPPING
            ? "line-clamp-3"
            : "line-clamp-4",
          isActiveStory && "!text-dolphin",
          linkClasses,
        )}
        href={url}
        dataYlk={{
          cnt_tpc: categoryLabel,
          cpos: cpos ?? i13nStartIndex + 1,
          g: id,
          ll4: getBadgeI13n(badges),
          pos: 1,
          subsec: i13nSubsec,
          ...(variant === STORY_VARIANTS.SHOPPING && { sec: "shopping-news" }),
          ...(variant === STORY_VARIANTS.SHOPPING && { subsec: moduleTitle }),
          ...COMMON_STREAM_LINK_I13N_PARAMS,
        }}
        disableModal={bypassModal}
        onClick={resumePosition}
      >
        {title}
      </ArticleLink>
    </h3>
  );
};
