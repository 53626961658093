import { createContext } from "react";

/**
 * This context helps with setting the correct CPOS (and other positions) for instrumentation.
 * CPOS works this way - https://docs.google.com/presentation/d/1LlX5BTTzKhL7X6d7QuyCLDD7H3QIxvcbSfiYSb1DyQk/edit#slide=id.g127ac572bc_6_364
 * i.e it is an index, and it's value increases per story/item within a module.
 * Now, for modules like Main stream (for you/more stories), or Trending topics - which are just a single `ul`/`ol` of items,
 * setting CPOS is the same as using the index from the `map` of the `for` loop where story items are rendered.
 *
 * However, the Top table component is divided into three sections: NTK COLUMN - first 3 items, NTK COLUMN - next 3 items, TopStoriesColumn - remaining stories
 * Each NTK Column has a lead item, and 2 follow items.
 * This I13nStartIndexContext context is used to maintain the CPOS value across these components, and follows a logic similar to https://react.dev/learn/passing-data-deeply-with-context .
 * TopTable sets the start context value as 0 for the first NTK Column, 3 for the second NTK column, and so on.
 * Each NTK Column increments the context value as needed, and provides it to it's child components.
 * The anchor link in every story items then, retrives the context/index value with the useContext hook.
 *
 * Category section stream module use this to for the stories left-right column UI.
 * This only facilitates avoiding prop-drilling, this context by itself does not provide any logical abstraction.
 * 
 * 
 * Cpos values across stories ->
 
                     +----------+                          
                     |  TopTable|                          
                     +----------+                          
                                                           
+------------+     +------------+        +----------------+
|  NTK Column|     | NTK Column |        |TopStoriesColumn|
+------------+     +------------+        +----------------+
                                                           
 +------------+     +-----------+        +----------------+
 | StoryLead  |     |StoryLead  |        |   StoryFollow  |
 |   Cpos 1   |     |  Cpos 4   |        |     Cpos 7     |
 +------------+     +-----------+        |                |
 +------------+     +-----------+        |                |
 | StoryFollow|     |StoryFollow|        |   StoryFollow  |
 |   Cpos 2   |     |  Cpos 5   |        |     Cpos 8     |
 |            |     |           |        |                |
 | StoryFollow|     |StoryFollow|        |   StoryFollow  |
 |   Cpos 3   |     |  Cpos 6   |        |     Cpos 9     |
 +------------+     +-----------+        |                |
                                         |   StoryFollow  |
                                         |     Cpos 10    |
                                         +----------------+
---------------------------------------------------------------   

* I13nStartIndexContext values across stories ->
           
                       ┌──────────────┐                                 
                       │              │                                 
                       │  Top Table   │                                 
                       │              │                                 
                       └────┬──┬─┬────┘                                 
         context={0}        │  │ │                                      
            ┌───────────────┘  │ │         context={6}                  
            │                  │ └──────────────────────────┐           
            ▼       context={3}▼                            ▼           
      ┌─────────────┐    ┌────────────┐           ┌────────────────────┐
┌─────┤  NTK Column │    │ NTK Column │           │ Top Stories Column │
│     └─────────────┘    └─┬──────────┘           └───────────────────┬┘
│                          │                                          │ 
│                          │                                          │ 
│ context={0}              │ context={3}                 context={6}  │ 
├────► <Story Lead>        ├──► <Story Lead>        <Story Follow> ◄──┤ 
│                          │                                          │ 
│                          │                                          │ 
│ context={0+1}            │ context={3+1}             context={6+1}  │ 
├────► <Story Follow>      ├──► <Story Follow>      <Story Follow> ◄──┤ 
│                          │                                          │ 
│ context={0+2}            │ context={3+2}                            │ 
└────► <Story Follow>      └──► <Story Follow>         context={6+i}  │ 
                                                       <...>  ◄───────┘ 
                                                                  
+-----------------------------------------------------------------
|                                                                 
 */

export const I13nStartIndexContext = createContext(0);
