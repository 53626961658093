import { CommentCount } from "@/components/common/CommentCount";
import { ReadTime } from "@/components/common/ReadTime";
import { ViewCount } from "@/components/common/ViewCount";

type Props = {
  commentCount?: number | null;
  viewCount?: number;
  wpm200?: number;
  separator?: string;
};

export const Stats = ({
  commentCount,
  viewCount,
  wpm200,
  separator,
}: Props) => {
  return (
    <>
      {viewCount ? (
        <ViewCount className="hidden md:block" count={viewCount} />
      ) : (
        <ReadTime className="hidden md:block" wpm200={wpm200} />
      )}
      {!!commentCount && (
        <CommentCount commentCount={commentCount} separator={separator} />
      )}
    </>
  );
};
