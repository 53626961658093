import classNames from "classnames";
import { type FC } from "react";

type Props = {
  authors: string;
  className?: string;
};
export const Authors: FC<Props> = ({ authors, className }) => {
  return (
    <span className={classNames(className, "label whitespace-nowrap")}>
      {authors}
    </span>
  );
};
