import { type FC } from "react";
import { useIntl } from "react-intl";
import { getImageSize } from "@/utils/ncp";
import { type StreamImage } from "../../shared/Stream/data";
import { AttributionImage } from "./AttributionImage";
import { Authors } from "./Authors";
import { Source } from "./Source";

type Props = {
  authors?: string[];
  image?: StreamImage | null;
  imageClassName?: string;
  sourceClassName?: string;
  source: string;
};

export const Attribution: FC<Props> = ({
  authors,
  image,
  imageClassName,
  sourceClassName,
  source,
}) => {
  const intl = useIntl();

  const formattedAuthorNames =
    authors &&
    intl.formatMessage(
      {
        id: "stream.AUTHOR_JOIN",
      },
      {
        length: authors.length,
        name: authors[0],
        secondName: authors[1],
      },
    );
  const resizedImage = image && getImageSize({ image, tag: "80x80" });
  const imageUrl = resizedImage?.url;

  let variant: React.ReactNode;

  if (formattedAuthorNames && source && imageUrl) {
    variant = (
      <>
        <AttributionImage className={imageClassName} imageUrl={imageUrl} />
        <Authors
          authors={formattedAuthorNames}
          className="after:mx-1.5 after:content-['·']"
        />
        <Source name={source} />
      </>
    );
  } else if (formattedAuthorNames && source) {
    variant = (
      <>
        <Authors
          authors={formattedAuthorNames}
          className="after:mx-1.5 after:content-['·']"
        />
        <Source name={source} />
      </>
    );
  } else if (imageUrl && source) {
    variant = (
      <>
        <AttributionImage className={imageClassName} imageUrl={imageUrl} />
        <Source name={source} />
      </>
    );
  } else {
    variant = <Source name={source} className={sourceClassName} />;
  }

  return (
    <div className="flex flex-row items-center truncate leading-[0]">
      {variant}
    </div>
  );
};
