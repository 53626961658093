import {
  useRef,
  useState,
  useEffect,
  type MutableRefObject,
  useCallback,
} from "react";

export const useObservedRef = (
  target: MutableRefObject<Element | null>,
  options: IntersectionObserverInit,
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observerRef = useRef<IntersectionObserver>();

  const stop = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  }, []);

  useEffect(() => {
    const element = target.current;

    if (!element) {
      return;
    }

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const inView = entry.isIntersecting;
        setIsIntersecting(inView);
      });
    }, options);

    observerRef.current.observe(element);

    return stop;
  }, [options, stop, target]);

  return isIntersecting;
};
