import classNames from "classnames";
import { Attribution } from "@/components/common/Attribution/Attribution";
import { type Attribution as AttributionType } from "@/lib/attribution";
import { useRequestContext } from "@/lib/request/client";
import { Stats } from "../Stream/StoryContent/Stats";

type Props = {
  attribution: AttributionType;
  className?: string;
  commentCount?: number;
  fontStyles?: string;
};

export const StoryMetadata = ({
  attribution,
  className,
  commentCount,
  fontStyles = "",
}: Props) => {
  const { features } = useRequestContext();

  return (
    <div
      className={classNames("flex flex-row items-center truncate", className)}
    >
      <Attribution source={attribution?.source} sourceClassName={fontStyles} />

      {features.moreForYouHideStats ? null : (
        <Stats
          commentCount={commentCount}
          separator={`before:mx-1.5 before:content-['/'] before:text-[#b0b0b0] ${fontStyles}`}
        />
      )}
    </div>
  );
};
