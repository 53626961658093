import { times } from "@yahoo-news/util";
import { useEffect, useRef } from "react";
import { useObservedRef } from "@/hooks";
import { StoryItemPlaceholder } from "../StoryItemPlaceholder";

export const StoryItemPlaceholders = ({
  className,
  onPlaceholdersVisible,
}: {
  className?: string;
  onPlaceholdersVisible: () => void;
}) => {
  const placeholdersRef = useRef(null);
  const isPlaceholderVisible = useObservedRef(placeholdersRef, {
    rootMargin: "0px 0px 1000px 0px",
  });

  useEffect(() => {
    if (isPlaceholderVisible) {
      onPlaceholdersVisible();
    }
  }, [isPlaceholderVisible, onPlaceholdersVisible]);

  return (
    <ul className="mt-6 flex flex-col gap-4 sm:gap-6" ref={placeholdersRef}>
      {times(7, (index) => {
        return (
          <li key={index}>
            <StoryItemPlaceholder className={className} />
          </li>
        );
      })}
    </ul>
  );
};
