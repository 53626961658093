import classNames from "classnames";
import { type FC } from "react";

type Props = { name: string; className?: string };
export const Source: FC<Props> = ({ name, className }) => {
  return (
    <span className={classNames("label truncate whitespace-nowrap", className)}>
      {name}
    </span>
  );
};
