import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { type ReadingMeta } from "@/components/shared/Stream/data";

const ReadTime: FC<ReadingMeta> = ({ className, wpm200 }) => {
  const intl = useIntl();
  const minutes = Math.round((wpm200 || 0) / 60);

  if (!minutes) {
    return null;
  }

  const readTime = intl.formatMessage(
    { id: "stream.READ_TIME" },
    { readTime: minutes },
  );

  return (
    <span
      className={classNames(
        className,
        "label whitespace-nowrap before:mx-1.5 before:content-['·']",
      )}
    >
      {readTime}
    </span>
  );
};
export { ReadTime };
