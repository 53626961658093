import { Icon } from "@yahoo/uds";
import { SpeechBubble } from "@yahoo/uds-icons";
import classNames from "classnames";
import React, { type FC, useMemo } from "react";
import { getAbbreviatedCount } from "./utils";

type CommentCountProps = {
  commentCount: number;
  separator?: string;
};

const CommentCount: FC<CommentCountProps> = ({
  commentCount,
  separator = "before:mx-1.5 before:content-['·']",
}) => {
  const abrvCommentCount = useMemo(
    () => getAbbreviatedCount(commentCount),
    [commentCount],
  );

  if (commentCount === 0) {
    return null;
  }

  return (
    <span
      className={classNames(
        "label flex flex-row items-center whitespace-nowrap",
        separator,
      )}
    >
      <Icon
        className="mr-1"
        name={SpeechBubble}
        variant="outline"
        size="sm"
        color="accent"
      />
      {abrvCommentCount}
    </span>
  );
};

export { CommentCount };
