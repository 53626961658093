import classNames from "classnames";
import { useMemo, type FC } from "react";
import { useIntl } from "react-intl";
import { getAbbreviatedCount } from "./utils";

type Props = {
  className?: string;
  count: number;
};

export const ViewCount: FC<Props> = ({ className, count }) => {
  const intl = useIntl();
  const abrvViewCount = useMemo(() => getAbbreviatedCount(count), [count]);

  if (count < 100) {
    return null;
  }

  return (
    <span
      className={classNames(
        "label whitespace-nowrap before:mx-1.5 before:content-['·']",
        className,
      )}
    >
      {abrvViewCount} {intl.formatMessage({ id: "stream.READS" })}
    </span>
  );
};
