import classNames from "classnames";
import { type FC } from "react";
import { PlaceholderBody } from "../PlaceholderBody";
import { gridClasses } from "../StoryItem";

export const StreamAdPlaceholder: FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div className={classNames(className, gridClasses, "flex")}>
      <div className="rounded bg-[#dfdfdf] pb-[110px] lg:col-span-4 xl:col-auto" />
      <div className="flex flex-col gap-2 lg:col-span-5 xl:col-auto">
        <PlaceholderBody />
      </div>
    </div>
  );
};
