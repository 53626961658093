import { useMemo } from "react";
import {
  SITE_BADGE_ALLOWLIST,
  type Badge,
  type BadgeType,
} from "@/components/common/badge/badge";
import { useRequestContext } from "@/lib/request/client";

export const useBadges = ({
  allowList,
  badges,
}: {
  allowList: BadgeType[];
  badges: Badge[];
}): Badge[] => {
  const { site } = useRequestContext();
  return useMemo(() => {
    return badges.filter(
      ({ type }) =>
        SITE_BADGE_ALLOWLIST[site]?.includes(type) && allowList.includes(type),
    );
  }, [allowList, badges, site]);
};
