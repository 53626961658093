import { type HPStreamViewParams } from "./data";

export const defaultStreamViewParams: HPStreamViewParams = {
  adsClusterEnabled: true,
  adsContentType: "video/mp4,application/vnd.apple.mpegurl",
  adsDynamicSlottingEnabled: false,
  adsEnabled: true,
  adsFrequency: 7,
  adsImageTags: "304x171|2|80",
  adsPublisherUrl: "www.yahoo.com",
  adsSectionId: 4250754,
  adsSlotsEnabled: true,
  adsStartIndex: 1,
  adsTimeout: 270,
  adsType: "STRM,STRM_CONTENT",
  adsVideoMaxCount: 2,
  bucketId: "MimicProviderListv2-copy,send_heimdall_homepage_bucket,seamless", // ??
  checkBettingEligibility: true,
  contentBadgesAppId: "yc",
  device: "desktop",
  diagnostics: false,
  imageTags: "448x252|2|95,74x74|2|95",
  lang: "en-US",
  listCount: 10,
  listStartIndex: 11,
  mabContentEnabled: true,
  minItemCount: 3,
  mmAdsType: "STRM",
  namespace: "creators",
  ntkEnabled: false,
  pageContext: {
    appName: "yc",
    lu: 0,
    pageType: "home",
    site: "creators",
  },
  pageIndex: 0,
  providerLogoImageTags: "80x80",
  providerLogoSizes: "80x80",
  region: "US",
  site: "frontpage",
  slideshowImageTags: "268x142|2|80,130x70|2|90",
  smartCropEnabled: false,
  ssl: true,
  storyline: false,
  storylineCount: 2,
  storylineImageTags: "170x80|2|80,70x70|2|90",
  subsite: "home",
};
